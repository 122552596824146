import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { ProfileState } from '@data/profile/profile-state';
import { NotificationService } from '@shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Roles, RoleSlug } from '@core/Roles';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WorkshopGuard {
  constructor(
    private store: Store,
    private router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(ProfileState.roles).pipe(
      filter(Boolean),
      map((roles: RoleSlug[]) => {
        return roles.some((role) => Roles.workshop().includes(role));
      }),
      map((can: boolean) => {
        if (can) {
          return true;
        } else {
          const url = state.url.replace(
            `/${Roles.PREFIX_WORKSHOP}/`,
            `/${this.store.selectSnapshot(ProfileState.routeRolePrefix)}/`
          );
          this.notificationService.notifyError(
            this.translateService.instant('you-do-not-have-access-to-this-part-of-the-application')
          );
          return this.router.parseUrl(url);
        }
      })
    );
  }
}
