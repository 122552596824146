import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedResource } from '@shared/interfaces/paged-resource';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DataResource, MessageResource } from '@shared/interfaces/data-resource';
import { Delete, Export, Id, Read, Update } from '@core/http/crud-model';
import { DeleteBatch } from '@core/http/crud-model/delete-batch';
import { SquareBracketsHttpUrlEncodingCodec } from '@core/http/square-brackets-http-url-encoding-codec';
import {
  AssignToVehiclePatchRequest,
  DisposalPatchRequest,
  PostTyrePayload,
  ReleaseDriverPatchRequest,
  ReleaseRecipientPatchRequest,
  TyreAdditionalStateValue,
  TyreLogResource,
  TyrePatchRequestParams,
  TyreResource,
  TyreStateResource,
} from './tyres.model';
import { MediaResource } from '../media/media.model';
import { DispositionResource } from '@data/dispositions/dispositions.model';
import { TyreStateValue } from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import { DepositWaitingPatchRequest } from '@pages-and-modals/add-new-tyres-modal/add-new-tires-modal.model';
import { FilterParams } from '@data/filtering/filtering.interface';
import { ReadById } from '@core/http/crud-model/read-by-id';
import { NoteService } from '@data/note/note.service';
import { LogsHistoryService } from '@data/tyres/logs-history.service';

export enum TyreSeason {
  SUMMER = 'summer',
  WINTER = 'winter',
  YEARLY = 'yearly',
}

const ENDPOINT = '/tyres';
const DEFAULT_WITH = 'notes,producer,product_tyre,current_log,draft_log';

@Injectable({
  providedIn: 'root',
})
export class TyresService
  implements
    Read<FilterParams, TyreResource>,
    ReadById<TyreResource>,
    Export<FilterParams, MediaResource>,
    Delete<null>,
    DeleteBatch<{ tyres: Id[]; password: string }>,
    Update<TyrePatchRequestParams, TyreResource> {
  constructor(private httpClient: HttpClient) {}

  get(params: FilterParams): Observable<PagedResource<TyreResource>> {
    return this.httpClient.get<PagedResource<TyreResource>>(`${ENDPOINT}`, {
      params: {
        ...params,
        with: DEFAULT_WITH,
      },
    });
  }

  getById(id: Id): Observable<DataResource<TyreResource>> {
    return this.httpClient.get<DataResource<TyreResource>>(`${ENDPOINT}/${id}`, {
      params: {
        with: 'notes,producer,product_tyre,current_log,draft_log,logs',
      },
    });
  }

  getTyresByVehicleId(id: number): Observable<TyreResource[]> {
    const params = { vehicle_id: id };
    return this.httpClient
      .get<PagedResource<TyreResource>>(ENDPOINT, {
        params,
      })
      .pipe(map((r) => r.data));
  }

  getStates(): Observable<PagedResource<TyreStateResource<TyreStateValue | TyreAdditionalStateValue>>> {
    return this.httpClient.get<PagedResource<TyreStateResource<TyreStateValue | TyreAdditionalStateValue>>>(
      `${ENDPOINT}/states`
    );
  }

  getTyreSeasonName(season: TyreSeason): string {
    switch (season) {
      case TyreSeason.SUMMER:
        return 'Letnie';
      case TyreSeason.WINTER:
        return 'Zimowe';
      case TyreSeason.YEARLY:
        return 'Całoroczne';
      default:
        throw new Error('Wrong enum TyreSeason value');
    }
  }

  add(params: PostTyrePayload): Observable<DataResource<TyreResource>> {
    return this.httpClient.post<DataResource<TyreResource>>(ENDPOINT, {
      ...params,
      with: DEFAULT_WITH,
    });
  }

  addLog(params: PostTyrePayload): Observable<DataResource<TyreLogResource>> {
    return this.httpClient.post<DataResource<TyreLogResource>>(ENDPOINT + '/logs', {
      ...params,
      with: DEFAULT_WITH,
    });
  }

  delete(id: Id, params?: null): Observable<DataResource<null>> {
    return this.httpClient.delete<DataResource<null>>(`${ENDPOINT}/${id}`);
  }

  deleteBatch(params?: { tyres: Id[]; password: string }): Observable<DataResource<null>> {
    let httpParams = Object.keys(params.tyres).reduce(
      (httpParams, key) => httpParams.append('tyres[]', params.tyres[key]),
      new HttpParams({
        encoder: new SquareBracketsHttpUrlEncodingCodec(),
      })
    );
    httpParams = httpParams.append('password', params.password);

    return this.httpClient.delete<DataResource<null>>(`${ENDPOINT}/batch`, {
      params: httpParams,
    });
  }

  assignToVehicle(params: AssignToVehiclePatchRequest): Observable<MessageResource> {
    return this.httpClient.patch<MessageResource>(`${ENDPOINT}/actions/assign-to-vehicle`, params);
  }

  disposal(params: DisposalPatchRequest): Observable<MessageResource> {
    return this.httpClient.patch<MessageResource>(`${ENDPOINT}/actions/disposal`, params);
  }

  export(params?: FilterParams): Observable<DataResource<MediaResource>> {
    return this.httpClient.get<DataResource<MediaResource>>(`${ENDPOINT}/actions/export`, {
      params: new HttpParams({ fromObject: params }),
    });
  }

  releaseDriver(params: ReleaseDriverPatchRequest): Observable<DataResource<DispositionResource>> {
    return this.httpClient.patch<DataResource<DispositionResource>>(`${ENDPOINT}/actions/release/driver`, params);
  }

  releaseRecipient(params: ReleaseRecipientPatchRequest): Observable<DataResource<DispositionResource>> {
    return this.httpClient.patch<DataResource<DispositionResource>>(`${ENDPOINT}/actions/release/recipient`, params);
  }

  moveToWaitingRoom(params: DepositWaitingPatchRequest): Observable<DataResource<DispositionResource>> {
    return this.httpClient.patch<DataResource<DispositionResource>>(`${ENDPOINT}/actions/deposit-waiting`, params);
  }

  reserve(params: Id[]): Observable<MessageResource> {
    return this.httpClient.patch<MessageResource>(`${ENDPOINT}/actions/reserve`, { tyre_ids: params });
  }

  removeReserve(params: Id[]): Observable<MessageResource> {
    return this.httpClient.patch<MessageResource>(`${ENDPOINT}/actions/remove-reserve`, { tyre_ids: params });
  }

  paidDeposit(params: Id[]): Observable<MessageResource> {
    return this.httpClient.patch<MessageResource>(`${ENDPOINT}/actions/paid-deposit`, { tyre_ids: params });
  }

  update(id: Id, params: TyrePatchRequestParams): Observable<DataResource<TyreResource>> {
    return this.httpClient.patch<DataResource<TyreResource>>(`${ENDPOINT}/${id}`, params);
  }

  noteFactory(id: Id): NoteService {
    return new NoteService(this.httpClient, ENDPOINT, id);
  }

  logsHistoryFactory(id: Id): LogsHistoryService {
    return new LogsHistoryService(this.httpClient, ENDPOINT, id);
  }
}
