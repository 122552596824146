import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from '@app/@wchfs-ui';
import { ButtonModule } from '@app/@wchfs-ui';
import { DatePickerModule } from '@app/@wchfs-ui';
import { DraggableModule } from '@app/@wchfs-ui';
import { ExpandedModule } from '@app/@wchfs-ui';
import { FormModule } from '@app/@wchfs-ui';
import { NavModule } from '@app/@wchfs-ui';
import { PaginationModule } from '@app/@wchfs-ui';
import { SnackBarsModule } from '@app/@wchfs-ui';
import { SvgIconModule } from '@app/@wchfs-ui';
import { TabModule } from '@app/@wchfs-ui';
import { TableModule } from '@app/@wchfs-ui';
import { TooltipModule } from './tooltip/tooltip.module';
import { TimepickerModule } from '@app/@wchfs-ui';
import { ModalModule } from '@app/@pso-ui/modal/modal.module';
import { InfoTooltipModule } from '@app/@wchfs-ui/lib/info-tooltip/info-tooltip.module';
import { MatDialogModule } from '@angular/material/dialog';

const modules = [
  CommonModule,
  OverlayModule,
  MatDialogModule,
  MatSnackBarModule,
  MatMenuModule,
  PortalModule,
  CdkTableModule,
  ScrollingModule,
  ReactiveFormsModule,
  FormsModule,
  TranslateModule,
  DragDropModule,
  FormModule,
  SvgIconModule,
  BreadcrumbModule,
  FormModule,
  SvgIconModule,
  NavModule,
  SnackBarsModule,
  DatePickerModule,
  InfoTooltipModule,
  TooltipModule,
  TableModule,
  DraggableModule,
  ButtonModule,
  ExpandedModule,
  PaginationModule,
  TabModule,
  TimepickerModule,
  ModalModule,
];

@NgModule({
  declarations: [],
  imports: [...modules],
  exports: [...modules],
})
export class WchfsUiModule {}
