import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@shared/services/notification.service';
import { AuthState } from '@data/auth/auth-state';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard {
  isAuthenticated = false;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private readonly store: Store,
    private translate: TranslateService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);
    if (!this.isAuthenticated) {
      this.notificationService.notifyError(this.translate.instant('guards.login-to-use'));
      return this.router.createUrlTree(['/auth/login']);
    }
    return this.isAuthenticated;
  }
}
