import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localePl from '@angular/common/locales/pl';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy, RouterModule } from '@angular/router';
import { HomeRoutingComponent } from '@core/home-routing/home-routing.component';
import { ApiPrefixInterceptor } from '@core/http/api-prefix.interceptor';
import { AuthorizationTokenInterceptor } from '@core/http/authorization-token-interceptor.service';
import { ErrorHandlerInterceptor } from '@core/http/error-handler.interceptor';
import { HttpStatusInterceptor } from '@core/http/http-status.interceptor';
import { LoadingHandlerService } from '@core/http/loader/loading-handler.service';
import { RouteReusableStrategy } from '@core/route-reusable-strategy';
import { setRootInjector } from '@core/setRootInjector';
import { AuthState } from '@data/auth/auth-state';
import { AuthorizationState } from '@data/authorization/authorization-state';
import { DepositsState } from '@data/deposits/deposits-state';
import { DispositionAddEditMetaState } from '@data/disposition-add-edit/disposition-add-edit-meta-state';
import { DispositionAddEditState } from '@data/disposition-add-edit/disposition-add-edit-state';
import { DispositionsParamsState } from '@data/dispositions/dispositions-params.state';
import { DispositionsState } from '@data/dispositions/dispositions-state.service';
import { FilteringState } from '@data/filtering/filtering.actions';
import { OrdersWithReservationState } from '@data/orders-with-reservation/orders-with-reservation-state';
import { ProductTyresState } from '@data/product-tyres/product-tyres.state';
import { ProfileState } from '@data/profile/profile-state';
import { ReleaseActionsState } from '@data/release-actions/release-actions-state';
import { RelocationsState } from '@data/relocations/relocations-state';
import { ServicesState } from '@data/services/services.state';
import { SidebarState } from '@data/sidebar/sidebar.state';
import { TyresState } from '@data/tyres/tyres-state';
import { VehiclesState } from '@data/vehicles/vehicles-state';
import { environment } from '@env/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from '@shared';
import { FleetInvoicesState } from '@data/invoices/fleet-invoices/fleet-invoices-state';
import { EstimatesGroupState } from '@data/estimates-group/estimates-group-state';
import { InvoicesPanelState } from '@data/invoices/invoices-panel/invoices-panel-state';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImpersonatingAsInterceptor } from '@core/http/impersonating-as.interceptor';
import * as Sentry from '@sentry/angular-ivy';
import { AuthorizationDetailsState } from '@data/authorization/authorization-details-state';
import { DispositionDetailsState } from '@data/dispositions/disposition-details-state';

const NGXS = [
  NgxsModule.forRoot(
    [
      SidebarState,
      AuthState,
      ProfileState,
      DispositionsState,
      DispositionsParamsState,
      DispositionAddEditState,
      DispositionAddEditMetaState,
      FilteringState,
      ServicesState,
      AuthorizationState,
      VehiclesState,
      DepositsState,
      ReleaseActionsState,
      TyresState,
      ProductTyresState,
      OrdersWithReservationState,
      EstimatesGroupState,
      RelocationsState,
      InvoicesPanelState,
      FleetInvoicesState,
      AuthorizationDetailsState,
      DispositionDetailsState,
    ],
    { developmentMode: !environment.production }
  ),
  NgxsStoragePluginModule.forRoot({
    key: [
      AuthState,
      // DispositionAddEditState
      DispositionAddEditMetaState,
      FilteringState,
    ],
  }),
  NgxsReduxDevtoolsPluginModule.forRoot(),
];

registerLocaleData(localePl);

@NgModule({
  imports: [
    BrowserAnimationsModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.serviceWorker }),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule,
    SharedModule,
    AppRoutingModule,
    ...NGXS,
  ],
  declarations: [AppComponent, HomeRoutingComponent],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: environment.showSentryDialog,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {
        //   TODO: Verify Sentry setup
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ImpersonatingAsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      deps: [LoadingHandlerService],
      useClass: HttpStatusInterceptor,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'PLN' },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
  constructor(private injector: Injector) {
    setRootInjector(injector);
    // Import guard
    // if (parentModule) {
    //   throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    // }
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
