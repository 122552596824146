<div class="pagination-wrapper">
  <div [class.pages-list]="!inputField" class="pagination">
    <form [formGroup]="pageForm" class="flex-row">
      <button #previousPage [disabled]="meta.current_page === 1" class="arrow-left">
        <wchfs-icon class="arrow-left" size="16" svgIcon="wchfs:arrow-left-pagination"></wchfs-icon>
      </button>
      <div *ngIf="inputField; else list" class="pagination-page-manage fx-flex-100">
        <wchfs-form-field>
          <input
            [max]="maxPages"
            [min]="1"
            [style.width]="meta.current_page < 10 ? '40px' : 'auto'"
            class="pagination-input"
            formControlName="page_number"
            type="number"
          />
        </wchfs-form-field>
        <span class="separator">z</span>
        <span class="pagination-total-pages">{{ maxPages }}</span>
      </div>
      <ng-template #list>
        <ul *ngIf="pages.length > splitLimit; else noSplit">
          <li *ngIf="meta.current_page >= 6">
            <button (click)="setPage(1)" [class.active]="1 === this.meta.current_page" wchfs-icon-button>1</button>
          </li>
          <li *ngIf="meta.current_page >= 6">
            <button (click)="setPage(2)" [class.active]="2 === this.meta.current_page" wchfs-icon-button>2</button>
          </li>
          <li *ngIf="meta.current_page >= 6" class="three-dots">...</li>
          <!-- TODO: limit the li elements   -->
          <li *ngFor="let page of pages | slice: firstMagicSplit():lastMagicSplit()">
            <!--             [ngStyle]="{ display: showPage(page) ? 'inline-block' : 'none' }"-->
            <button
              (click)="setPage(page)"
              [class.active]="page === this.meta.current_page"
              [disabled]="page === meta.current_page"
              wchfs-icon-button
            >
              <em [class.text-small]="page >= 1000">{{ page }}</em>
            </button>
          </li>
          <li *ngIf="meta.current_page < maxPages - 4" class="three-dots">...</li>
          <li *ngIf="meta.current_page <= maxPages - 5">
            <button
              (click)="setPage(maxPages - 1)"
              [class.active]="maxPages - 1 === this.meta.current_page"
              wchfs-icon-button
            >
              <em [class.text-small]="pages[maxPages - 2] >= 1000">{{ maxPages - 1 }}</em>
            </button>
          </li>
          <li *ngIf="meta.current_page <= maxPages - 5">
            <button (click)="setPage(maxPages)" [class.active]="maxPages === this.meta.current_page" wchfs-icon-button>
              <em [class.text-small]="pages[maxPages - 1] >= 1000">{{ maxPages }}</em>
            </button>
          </li>
        </ul>

        <ng-template #noSplit>
          <ul>
            <li *ngFor="let page of pages">
              <button
                (click)="setPage(page)"
                [class.active]="page === this.meta.current_page"
                [disabled]="page === meta.current_page"
                wchfs-icon-button
              >
                <em [class.text-small]="page >= 1000">{{ page }}</em>
              </button>
            </li>
          </ul>
        </ng-template>
      </ng-template>
      <button #nextPage [disabled]="meta.current_page === maxPages" class="arrow-right">
        <wchfs-icon size="16" svgIcon="wchfs:arrow-right-pagination"></wchfs-icon>
      </button>
    </form>
  </div>
</div>
