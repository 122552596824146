<form [formGroup]="pageForm" class="flex-row">
  <wchfs-form-field class="keyword-control">
    <wchfs-label>{{ labels.showLabel }}</wchfs-label>
    <wchfs-select formControlName="per_page" clearOption="false">
      <wchfs-option *ngFor="let perPageOption of options" [value]="perPageOption"
        >{{ perPageOption.toString() }}
      </wchfs-option>
    </wchfs-select>
  </wchfs-form-field>
  <div class="count-info align-center items-center fx-flex-100">
    {{ meta.from }} - {{ meta.to }} {{ labels.ofLabel }} {{ meta.total }}
  </div>
</form>
